.avatar-card {
  @apply p-app-base max-w-app-avatar-card;
  @apply hidden bg-app-background-primary rounded-app-base;
  position: absolute;
  left: 103%;
  transform: translateY(-25%);
  z-index: 1000;
  color: white;
  text-align: center;
  box-shadow:  rgba(236, 127, 127, 0.2) 0px 0px 15px, 
  rgba(255, 255, 255, 0.15) 0px 0px 3px 1px;

  /* box-shadow: 0 0 6px 1px rgba(166, 160, 160, 0.5); */
  opacity: 0;
}

.avatar-image-container:hover {
  .avatar-card {
    @apply flex flex-col;
    opacity: 1;
    animation: fadein 0.2s linear;
  }

}

@keyframes fadein {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fadeout {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.avatar-moto {
  @apply flex flex-1 justify-start pb-app-base;

  p {
    @apply text-start;
  }
}