.voiting-man-icon-small {
  @apply flex flex-col cursor-pointer;

  .voiting-man-circle-small {
    @apply h-app-base w-app-base rounded-[50%] mb-app-xs transition-transform duration-100 ease-in-out;
  }

  .voiting-man-rectangle-small {
    @apply h-app-s w-app-base transition-transform duration-100 ease-in-out;
  }

  .voiting-man-small-orange {
    @apply bg-app-primary;
  }

  .voiting-man-small-purple {
    @apply bg-app-secondary;
  }

}


  /* .voiting-man-icon-small:hover .voiting-man-circle-small,
  .voiting-man-icon-small:hover .voiting-man-rectangle-small {
    @apply transform scale-125;
  } */