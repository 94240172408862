@font-face {
  font-family: 'Roboto-italic-thin';
  src: url('./Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-medium';
  src: url('./Roboto/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-light';
  src: url('./Roboto/Roboto-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('./Roboto/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
}