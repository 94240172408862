.voting-bar-popup-container {
  @apply flex justify-center pointer-events-none ;

  .voting-bar-popup {
    @apply opacity-0 flex bg-app-third mb-app-xs p-app-s gap-app-sm justify-end rounded-app-xs;
  }

  .votes-count {
   @apply flex self-end ml-app-xs text-app-sm;
  }
}