
.navigation-mobile {
  /* @apply laptop:hidden; */
  position: relative;
  width: 100%;
}

.menu-toggle-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
}


.mobile-navigation {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;
  z-index: 1000;

  ul {
    list-style: none;
  }
  
  ul li {
    margin: 20px 0;
  }
  
  ul li a {
    font-size: 24px;
    text-decoration: none;
  }
  
  ul li a:hover {
    text-decoration: underline;
  }
}

.menu-open {
  transform: translateX(0);
}

.menu-closed {
  transform: translateX(-100%);
}


