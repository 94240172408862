@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global colors, use them in another file like this - background-color: var(--app-secondary); */
:root {
  --app-primary: #e79f29;
  --app-secondary: #33528b;
  --app-third: #313131;
  --app-gray: #898989;
  --app-white: #ffffff;
  --app-card: #111827;
  --app-background: #080b0e;
}

@layer utilities {

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #080b0e;
  display: flex;
  /* Here the 100dvh is because on mobile the vh is defferent */
  height: 100dvh;
  color: white;
  font-family: 'Roboto-normal', sans-serif;
  overflow: hidden;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  /* height: 100vh; */

  /* max-width: 1024px;
  overflow: hidden; */

}
