.signin-form-wrapper {
  @apply relative min-w-[300px] max-w-[400px] mx-auto flex justify-center my-app-base rounded-app-base p-app-base;
  z-index: 4001;

  .signin-form-container {
    @apply flex flex-1 flex-col justify-center  border-app-xs border-app-third rounded-app-base p-[32px];

    background: linear-gradient(to top,
        #050F18 0%,
        #04111F 50%,
        #04111F 50%,
        #050F18 100%);
  }
}

