.voiting-man-icon {
  @apply flex flex-col cursor-pointer;

  .voiting-man-circle {
    @apply h-app-xl w-app-xl rounded-[50%] mb-app-xs transition-transform duration-100 ease-in-out;
  }

  .voiting-man-rectangle {
    @apply h-app-base-2 w-app-xl transition-transform duration-100 ease-in-out;
  }

  .voiting-man-orange {
    @apply bg-app-primary border-app-xs border-app-primary;
  }

  .voiting-man-purple {
    @apply bg-app-secondary border-app-xs border-app-secondary;
  }
}

.voiting-man-icon:hover .voiting-man-circle,
.voiting-man-icon:hover .voiting-man-rectangle {
  @apply transform scale-125;
}