.error-page-wrapper {

    background-image: url("../../../public/OIG3.jpeg");
    background-color: #02041D;
    /* The image used */
    /* background-color: #cccccc; */
    /* Used if the image is unavailable */
    height: 100%;
    /* You must set a specified height */
    background-position:center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: contain;
    /* Resize the background image to cover the entire container */
}