/* Custom animation for the bottom border */
.input-border-bottom::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: rgb(221, 211, 211); /* You can replace this with a Tailwind color class */
  transition: width 0.4s ease, left 0.4s ease;
}

.input-border-bottom-focused::before {
  width: 100%;
  left: 0;
}
