.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 4000;

  .backdrop {
    /* @apply bg-gradient-to-b from-transparent to-blue-500; */
    background: linear-gradient(to top,
        #050F18 0%,
        #04111F 50%,
        #04111F 50%,
        #050F18 100%);
    position: absolute;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 4000;
  }

}