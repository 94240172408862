.share-button-wrapper {
  @apply relative;
}
.share-popup {
  @apply hidden;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 100;

}
.share-button-wrapper:hover {
  .share-popup {
    @apply flex;
  }
}

.copy-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #0056b3;
}
.copied-notification {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 1;
  animation: fadeOut 2s forwards;
}