.input-actions-buttons {
  @apply relative;
}

.input-actions-buttons::after {
  @apply bg-transparent rounded-[50%] opacity-30 transition-all duration-150 ease-in-out;
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.8);
  z-index: 1;
}

.input-actions-buttons:hover::after {
  @apply transform scale-125 bg-app-secondary opacity-30;
  transform: translate(-50%, -50%) scale(1.8);
}

.input-actions-buttons {
  @apply text-app-gray transition-all duration-150 ease-in-out;
}

.input-actions-buttons:hover {
  @apply text-app-secondary;
}