
.input-container {
  /* @apply bg-app-background-primary; */
  position: relative;
  flex: 1;
  display: flex;
  /* background-color: black; */
  border-radius: 14px;
}

.input-container input {
  @apply border-[0.5px] border-app-gray ;

  width: 100%;
  height: 46px;
  padding: 10px;
  font-size: 16px;
  background-color: var(--app-background);
  color: white;
  border-radius: 14px;
  outline: none;
  transition: all 0.3s ease;
}

.input-container input::placeholder {
  color: transparent;
}

.input-container label {
  @apply text-app-gray ;
  position: absolute;
  padding: 0 2px;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  background: linear-gradient(to top,
      var(--app-background) 0%,
      var(--app-background) 50%,
      transparent 50%,
      transparent 100%);
}

.input-container input:focus {
  border: 0.5px solid #2f80ed;
}

.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label {
  top: 0;
  left: 10px;
  font-size: 12px;
  color: #2f80ed;
  
}
.input-container input:not(:focus) + label {
  color: #999;
}

.input-icon-right {
  position: absolute;
  padding: 0 20px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}