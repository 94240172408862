.checkbox-label {
  @apply relative flex items-center cursor-pointer;

  .checkbox-container {
    @apply flex relative w-[22px] h-[22px] border-app-xs;
    @apply border-app-gray outline-4 hover:border-blue-400 rounded-app-xs ;

    i {
      @apply text-transparent;
    }
  }
}
.checkbox-container:has(input:checked) {
  /* Styles when the checkbox inside the span is checked */
  @apply bg-[#3867d6] border-[#3867d6] transition-all duration-150 ;

  i {
    @apply text-white;
  }
}