.voiting-man-icon-hallow {
  @apply flex flex-col cursor-pointer;

  .voiting-man-circle-hallow {
    @apply h-app-xl w-app-xl rounded-[50%] mb-app-xs transition-transform duration-100 ease-in-out;
  }

  .voiting-man-rectangle-hallow {
    @apply h-app-base-2 w-app-xl transition-transform duration-100 ease-in-out;
  }

  .voiting-man-hallow-orange {
    @apply border-app-xs border-app-primary;
  }

  .voiting-man-hallow-purple {
    @apply border-app-xs border-app-secondary;
  }

  
}

.voiting-man-icon-hallow:hover .voiting-man-circle-hallow,
  .voiting-man-icon-hallow:hover .voiting-man-rectangle-hallow{
    @apply transform scale-125;
  }